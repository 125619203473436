.perso-block {
    margin-bottom: 24px;
    h2 {
        margin-bottom: 16px;
    }
    h4 {
        margin-bottom: 8px;
    }
    input[type="number"],
    h4 ~ .search-input {
        height: 30px;
        border-radius: 5px;
        padding: 0 8px;
        border: 1px solid #848484;
    }
}
