.color-input {
    display: flex;
    width: 120px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #848484;
    input {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        outline: none;
        border: none;
        margin: 0;
        padding: 0 0 0 8px;
        display: flex;
        align-items: center;
    }
    > div {
        height: 100%;
        width: 30px;
        flex-shrink: 0;
        cursor: pointer;
    }
}
