.option_inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    select,
    .multi-select {
        border: 1px solid #848484;
        outline: none;
        // cursor: pointer;
        background: none;
        // color: var(--green-enjoy);
        padding: 8px;
        border-radius: 5px;
        font-weight: 500;
        min-height: 32px;
        display: flex;
        align-items: center;
        font-family: "Roboto", sans-serif;
        // height: 32px;
        box-sizing: border-box;
    }
    input {
        width: 100%;
        outline: none;
        height: 32px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #848484;
        font-family: "Roboto";
    }
    button {
        border: 1px solid #848484;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
        border-radius: 5px;
        background: none;
        font-weight: 500;
        font-family: "Roboto";
        margin-bottom: 8px;
        cursor: pointer;
        height: 32px;
        box-sizing: border-box;
        &:hover,
        &.current {
            border-color: var(--green-enjoy);
            color: white;
            background-color: var(--green-enjoy);
        }
        &:not(.current) svg {
            display: none;
        }
    }
}
