.menu {
    width: 80px;
    height: 100vh;
    position: relative;
    z-index: 2;
    .main-menu {
        background-color: var(--green-enjoy);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        max-width: 100%;
        height: 100%;
        box-shadow: 3px 0px 8px rgba(0,0,0,0.1);
        z-index:3;
        position: relative;
        .logo {
            border-radius: 5px;
            margin-bottom: 24px;
        }
        .icones {
            width: 100%;
            height: auto;
            padding: 12px;
            box-sizing: border-box;
            margin-bottom: 16px;
            border: 2px solid white;
            stroke: white;
            border-radius: 5px;
            cursor: pointer;
            &.active {
                background-color: white;
                stroke: var(--green-enjoy);
            }
        }
    }
    .second-menu {
        position: absolute;
        height: 100vh;
        width: auto;
        min-width: 250px;
        left: 80px;
        top: 0;
        background-color: #FFF;
        padding: 24px 32px;
        box-shadow: 3px 0px 8px rgba(0,0,0,0.1);
        z-index: 2;
        box-sizing: border-box;
        overflow-y: auto;
    }
}