.multi-select {
    position: relative;
    background-color: green;
    padding: 0;
    z-index: 100;
    .selected {
        cursor: default;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        min-height: 10px;
        > div {
            display: flex;
            flex-wrap: wrap;
            // max-width: 80%;
            > span {
                cursor: pointer;
                background-color: var(--green-enjoy);
                color: white;
                padding: 3px 6px;
                margin: 2px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                &:hover {
                    background-color: #94ac37;
                }
                svg {
                    margin-left: 4px;
                }
            }
        }
    }
    .options {
        position: absolute;
        top: calc(100% + 8px);
        border-radius: 5px;
        border: 1px solid #848484;
        left: -1px;
        right: 1px;
        // width: calc(100% + 2px);
        background-color: white;
        box-sizing: border-box;
        max-height: 400px;
        overflow-y: auto;
        padding: 4px 0px;
        .option-item {
            cursor: pointer;
            padding: 4px 8px;
            &:hover {
                background-color: var(--green-enjoy);
                color: white;
                // background-color: rgb(235, 235, 235);
            }
        }
    }
}
