.code-popup-overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100vh;
    inset: 0;

    .code-popup {
        position: relative;
        background-color: white;
        border-radius: 10px;
        top: 15%;
        left: 18%;
        padding: 64px 80px;
        box-sizing: border-box;
        // height: 65%;
        width: 70%;

        .title-line {
            margin-bottom: 32px;
            h2 {
                margin: 0;
            }
            a {
                position: absolute;
                left: 1060px;
                top: 65px;
                font-size: 16px;
                color: #b3cf42;
            }
        }

        .code-flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                margin-top: 32px;
                margin-bottom: 32px;
            }

            .code-rectangle {
                position: relative;
                background-color: #f1f1f1;
                padding: 16px 24px;
                box-sizing: border-box;
                border-radius: 5px;
                p {
                    word-break: break-word;
                    margin: 0;
                    color: #727272;
                }
                img {
                    position: absolute;
                    right: 12px;
                    bottom: 8px;
                }
                // .img-kifaychiay {
                //     bottom: %;
                // }
            }
            // .code-instructions {
            //     height: 50%;
            //     width: 100%;
            //     button {
            //         position: absolute;
            //         left: 62.5%;
            //         top: 85%;
            //         background-color: #b3cf42;
            //         border: none;
            //         color: white;
            //         width: 250px;
            //         height: 62px;
            //         border-radius: 6px;
            //         font-size: 16px;
            //         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            //             "Open Sans", "Helvetica Neue", sans-serif;
            //         font-weight: 600;
            //     }
            //     button:active {
            //         border: #b3cf42;
            //         background-color: white;
            //         color: #b3cf42;
            //     }

            //     p {
            //         line-height: 22px;
            //         padding: 12px;
            //     }
            // }
        }
    }
}
