@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  background-color: white;
  --green-enjoy: #b3cf42;
}

.menu{width:80px;height:100vh;position:relative;z-index:2}.menu .main-menu{background-color:var(--green-enjoy);display:flex;flex-direction:column;justify-content:flex-start;align-items:center;padding:16px;box-sizing:border-box;max-width:100%;height:100%;box-shadow:3px 0px 8px rgba(0,0,0,.1);z-index:3;position:relative}.menu .main-menu .logo{border-radius:5px;margin-bottom:24px}.menu .main-menu .icones{width:100%;height:auto;padding:12px;box-sizing:border-box;margin-bottom:16px;border:2px solid #fff;stroke:#fff;border-radius:5px;cursor:pointer}.menu .main-menu .icones.active{background-color:#fff;stroke:var(--green-enjoy)}.menu .second-menu{position:absolute;height:100vh;width:auto;min-width:250px;left:80px;top:0;background-color:#fff;padding:24px 32px;box-shadow:3px 0px 8px rgba(0,0,0,.1);z-index:2;box-sizing:border-box;overflow-y:auto}
.option_inputs{display:flex;flex-direction:column;margin-bottom:24px}.option_inputs select,.option_inputs .multi-select{border:1px solid #848484;outline:none;background:none;padding:8px;border-radius:5px;font-weight:500;min-height:32px;display:flex;align-items:center;font-family:"Roboto",sans-serif;box-sizing:border-box}.option_inputs input{width:100%;outline:none;height:32px;box-sizing:border-box;display:flex;align-items:center;border-radius:5px;padding:8px;box-sizing:border-box;border:1px solid #848484;font-family:"Roboto"}.option_inputs button{border:1px solid #848484;display:flex;align-items:center;justify-content:space-between;padding:6px;border-radius:5px;background:none;font-weight:500;font-family:"Roboto";margin-bottom:8px;cursor:pointer;height:32px;box-sizing:border-box}.option_inputs button:hover,.option_inputs button.current{border-color:var(--green-enjoy);color:#fff;background-color:var(--green-enjoy)}.option_inputs button:not(.current) svg{display:none}
.search-input{display:flex;position:relative}
.multi-select{position:relative;background-color:green;padding:0;z-index:100}.multi-select .selected{cursor:default;position:relative;display:flex;align-items:center;justify-content:space-between;width:100%;height:100%;min-height:10px}.multi-select .selected>div{display:flex;flex-wrap:wrap}.multi-select .selected>div>span{cursor:pointer;background-color:var(--green-enjoy);color:#fff;padding:3px 6px;margin:2px;border-radius:4px;display:flex;align-items:center}.multi-select .selected>div>span:hover{background-color:#94ac37}.multi-select .selected>div>span svg{margin-left:4px}.multi-select .options{position:absolute;top:calc(100% + 8px);border-radius:5px;border:1px solid #848484;left:-1px;right:1px;background-color:#fff;box-sizing:border-box;max-height:400px;overflow-y:auto;padding:4px 0px}.multi-select .options .option-item{cursor:pointer;padding:4px 8px}.multi-select .options .option-item:hover{background-color:var(--green-enjoy);color:#fff}
.perso-block{margin-bottom:24px}.perso-block h2{margin-bottom:16px}.perso-block h4{margin-bottom:8px}.perso-block input[type=number],.perso-block h4~.search-input{height:30px;border-radius:5px;padding:0 8px;border:1px solid #848484}
.color-input{display:flex;width:120px;height:30px;border-radius:5px;overflow:hidden;border:1px solid #848484}.color-input input{width:100%;box-sizing:border-box;height:100%;outline:none;border:none;margin:0;padding:0 0 0 8px;display:flex;align-items:center}.color-input>div{height:100%;width:30px;flex-shrink:0;cursor:pointer}
#root{display:flex;flex-direction:row-reverse;width:100vw;height:100vh;overflow:hidden;background-color:#f5f6f8}.noscrollbar{-ms-overflow-style:none;scrollbar-width:none}.noscrollbar::-webkit-scrollbar{display:none}.widget-page{width:calc(100vw - 80px);padding:24px;box-sizing:border-box;min-height:100vh;overflow-y:auto;align-items:center;display:flex}.widget-page #tnj{background-color:#f5f6f8 !important;width:100%}.widget-page #tnj .tnj-carousel-arrows{background-color:#f5f6f8 !important}.salle_attente{width:100vw;height:100vh;display:flex;align-items:center;justify-content:center;background-color:#fff;cursor:default}.salle_attente>div{display:flex;flex-direction:column;margin-bottom:20vh;padding:36px;border-radius:10px;box-shadow:0px 0px 8px rgba(0,0,0,.1);width:33vw}.salle_attente>div h1{margin:0 0 12px;letter-spacing:1px}.salle_attente>div h3{margin:0 0 12px}.salle_attente>div p{margin:0 0 28px;font-weight:400;font-size:18px;line-height:140.19%;color:#848484}.salle_attente>div .green{color:var(--green-enjoy)}.salle_attente>div input[type=text]{outline:none;display:flex;align-items:center;height:32px;padding:0 16px;border:1px solid #848484;border-radius:5px;font-weight:500}.salle_attente>div>div{display:flex;align-items:center;padding:8px 16px;margin:8px 0 16px}.salle_attente>div>div>input{margin:0 8px 0 0;cursor:pointer;height:16px;width:16px}.salle_attente>div button{border:none;outline:none;background-color:var(--green-enjoy);color:#fff;padding:12px;border-radius:5px;font-size:16px;font-weight:600;cursor:pointer}
.code-popup-overlay{background:rgba(0,0,0,.4);position:fixed;z-index:2;width:100%;height:100vh;inset:0}.code-popup-overlay .code-popup{position:relative;background-color:#fff;border-radius:10px;top:15%;left:18%;padding:64px 80px;box-sizing:border-box;width:70%}.code-popup-overlay .code-popup .title-line{margin-bottom:32px}.code-popup-overlay .code-popup .title-line h2{margin:0}.code-popup-overlay .code-popup .title-line a{position:absolute;left:1060px;top:65px;font-size:16px;color:#b3cf42}.code-popup-overlay .code-popup .code-flex{display:flex;flex-direction:column;justify-content:center;align-items:center}.code-popup-overlay .code-popup .code-flex p{margin-top:32px;margin-bottom:32px}.code-popup-overlay .code-popup .code-flex .code-rectangle{position:relative;background-color:#f1f1f1;padding:16px 24px;box-sizing:border-box;border-radius:5px}.code-popup-overlay .code-popup .code-flex .code-rectangle p{word-break:break-word;margin:0;color:#727272}.code-popup-overlay .code-popup .code-flex .code-rectangle img{position:absolute;right:12px;bottom:8px}
