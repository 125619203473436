#root {
    display: flex;
    flex-direction: row-reverse;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #f5f6f8;
}

.noscrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.noscrollbar::-webkit-scrollbar {
    display: none;
}
.widget-page {
    width: calc(100vw - 80px);
    padding: 24px;
    box-sizing: border-box;
    min-height: 100vh;
    overflow-y: auto;
    align-items: center;
    display: flex;
    #tnj {
        background-color: #f5f6f8 !important;
        width: 100%;
        .tnj-carousel-arrows{
            background-color: #f5f6f8 !important;
        }
    }
}

.salle_attente {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: default;
    > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20vh;
        padding: 36px;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        width: 33vw;
        h1 {
            margin: 0 0 12px;
            letter-spacing: 1px;
        }
        h3 {
            margin: 0 0 12px;
        }
        p {
            margin: 0 0 28px;
            font-weight: 400;
            font-size: 18px;
            line-height: 140.19%;
            color: #848484;
        }
        .green {
            color: var(--green-enjoy);
        }
        input[type="text"] {
            outline: none;
            display: flex;
            align-items: center;
            height: 32px;
            padding: 0 16px;
            border: 1px solid #848484;
            border-radius: 5px;
            font-weight: 500;
        }
        > div {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin: 8px 0 16px;
            > input {
                margin: 0 8px 0 0;
                cursor: pointer;
                height: 16px;
                width: 16px;
            }
        }
        button {
            border: none;
            outline: none;
            background-color: var(--green-enjoy);
            color: white;
            padding: 12px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}
